.wrapper {
	@apply px-3 pb-4;

	.info {
		@apply grid grid-cols-2 gap-4;
	}

	@media (max-width: 770px) {
		@apply flex flex-col-reverse;
	}
}
