.title {
	@apply flex flex-col mb-2;

	.btns {
		@apply flex gap-2;

		button {
			@apply float-left mb-2;
		}
	}

	> h1 {
		@apply text-center w-full;
	}
}
