.mainInfo {
	@apply flex flex-col gap-2 justify-evenly flex flex-col justify-center px-6 py-3 col-start-2 self-baseline border-black rounded-lg shadow-lg  bg-slate-400 bg-opacity-60 max-md:col-span-2 max-md:row-start-1;

	.article {
		min-height: 350px;
	}

	> p {
		@apply max-[428px]:text-sm;
	}

	> p > span {
		@apply pl-3;
	}

	.mainInfoEl {
		@apply flex gap-x-2;
		p {
			@apply text-lg;
		}
	}
	.btns {
		@apply flex justify-center self-center gap-2 max-[1150px]:flex-col max-[1150px]:w-2/3 max-[850px]:w-full max-md:flex-row max-[550px]:flex-col max-[550px]:w-2/3 max-[430px]:w-full;
	}
}
