.common {
	> label {
		@apply block;

		> span {
			@apply text-xs uppercase text-gray-600 block;
		}
	}
}

.textarea {
	@apply border-2 border-solid py-1 px-2 bg-white rounded-md text-lg;
	border-color: #999aa5;

	&::placeholder {
		@apply text-lg;
	}
	&:active {
		@apply border-primary;
	}

	&:disabled {
		color: hsl(0, 0%, 60%);
		background-color: hsl(0, 0%, 95%);
		border-color: #999aa5;
	}
}

.field {
	@apply text-left mb-2;

	input,
	.textarea {
		width: 100%;
	}

	&.error {
		input,
		.textarea {
			border-color: red;
		}

		> p {
			@apply text-sm w-full text-red-600;
		}
	}
}

.required {
	&::before {
		content: '*';
		color: red;
	}
}
