.wrapper {
	@apply flex flex-col p-2;

	.rules {
		@apply pl-6 mb-4;
	}

	.addBtn {
		@apply w-[200px] m-auto block my-4;
	}

	.table {
		@apply overflow-auto w-full;

		.btns {
			@apply flex flex-col gap-2;
			button {
				@apply w-[220px] flex gap-2;
			}
		}
	}
}

@media (max-width: 375px) {
	.addEmployee {
		@apply p-2;
	}
}
