.wrapper {
	@apply flex flex-col p-2;

	.table {
		@apply overflow-auto;
		> table {
			min-width: 768px;
			width: 100%;

			.btns {
				@apply flex flex-col gap-2;
			}
		}
	}

	h1 {
		@apply self-center;
	}
}
