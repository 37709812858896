.wrapper {
	@apply flex flex-col p-2;

	.btns {
		@apply flex flex-col gap-y-2 justify-center max-xl:gap-y-1;
	}

	h1 {
		@apply self-center;
	}

	.table {
		@apply mt-5 overflow-auto;
		> table {
			min-width: 768px;
			width: 100%;
		}
	}
}
