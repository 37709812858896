.wrapper {
	@apply flex flex-col mx-auto w-1/3;
	.select {
		@apply py-1;
		span {
			@apply text-xs uppercase text-gray-600 block;
		}
		&.errorSelect > div > div {
			border-color: red;
		}
	}
	.errorText {
		@apply text-sm w-full self-center mx-auto text-red-600;
	}
	> h1 {
		@apply mx-auto text-center;
	}
	> button {
		@apply self-center;
	}
	@media (min-width: 1440px) {
		width: 450px;
	}
	@media (max-width: 1000px) {
		@apply w-1/2;
	}
	@media (max-width: 710px) {
		@apply w-full;
	}
}
