.table {
	// table-layout: fixed;
	width: 100%;
	min-width: 768px;

	thead {
		background: lightgray;
		margin: 0;
		position: sticky;
		top: 0;
	}
	th,
	td {
		word-wrap: break-word;
	}
}

.resizer {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 5px;
	background: rgba(0, 0, 0, 0.5);
	cursor: col-resize;
	user-select: none;
	touch-action: none;
}
.resizer .isResizing {
	background: blue;
	opacity: 1;
}

.pagination {
	@apply flex justify-center gap-2;

	button {
		@apply cursor-pointer;

		&:disabled {
			@apply cursor-no-drop;
		}
	}
}

@media (hover: hover) {
	.resizer {
		opacity: 0;
	}

	*:hover > .resizer {
		opacity: 1;
	}
}
