.button {
	@apply text-lg px-3 py-1 border-0 rounded-md cursor-pointer no-underline shadow-md flex justify-center items-center;

	&:disabled,
	&[disabled],
	&:disabled:hover {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
		cursor: no-drop;
	}
}

.white {
	@apply bg-white text-black border-primary border border-solid transition-all outline-none;

	&:hover {
		@apply bg-primary text-white;
	}

	&:active {
		@apply bg-primary text-white;
		@apply bg-primary text-white;
	}
}

.red {
	@apply bg-white text-red-500 border-red-500 border border-solid transition-all;

	&:hover {
		@apply bg-red-500 border-red-500 border border-solid opacity-100 text-white transition-all;

		svg {
			@apply fill-white transition-all;
		}
	}
	&:active {
		@apply scale-105;
	}
}

.pagination {
	@apply bg-white text-black border-primary border border-solid transition-all outline-none;
	border-radius: 100%;
	height: 40px;
	width: 40px;

	&:hover {
		@apply bg-primary text-white;
	}

	&:active {
		@apply bg-primary text-white;
		@apply bg-primary text-white;
	}
}

.yellow {
	@apply bg-white text-yellow-500 border-yellow-500 border border-solid transition-all;

	&:hover {
		@apply bg-yellow-500 border-yellow-500 border border-solid opacity-100 text-white transition-all;

		svg {
			@apply fill-white transition-all;
		}
	}
	&:active {
		@apply scale-105;
	}
}
