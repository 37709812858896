.wrapper {
	@apply flex flex-col p-2;

	.table {
		@apply overflow-auto;

		.btns {
			@apply flex flex-col gap-2;
		}
	}

	.toggle {
		@apply pl-20 mb-2;
	}
}
