.modal {
	.header {
		@apply flex justify-between;

		svg {
			@apply cursor-pointer;
		}
	}

	.body {
		@apply flex flex-row justify-evenly;

		p {
			@apply px-3 flex flex-col;
		}

		&.form {
			@apply flex-col;
		}

		&.choice {
			@apply flex flex-col gap-2;
		}
	}
}
