@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	--navigationPanel: rgba(42, 42, 43);
	--navigationPanelItemHover: rgba(22, 22, 23, 0.1);
	--navigationPanelText: rgba(235, 235, 235);
	--navigationPanelTextHover: #ffffff;

	--catalogMenu: rgba(52, 52, 53);
	--catalogMenuHover: rgba(62, 62, 63);
	--catalogMenuScroll: rgba(72, 72, 73);

	--navigationButtonBackground: rgba(222, 222, 222);
	--navigationButtonText: rgba(22, 22, 23);
	--navigationButtonBackgroundHover: rgba(202, 202, 202);
	--navigationButtonTextHover: rgba(42, 42, 43);
	--navigationButtonBackgroundActive: rgba(132, 132, 132);
	--navigationButtonDisabled: rgba(200, 200, 200);

	--black: rgb(0, 0, 0);
	--red: rgb(70, 70, 70);
	--green: rgb(85, 128, 0);
	--blue: #007aff;
	--darkgray: #6e6e73;
	--background: #f1f1f1;
	--white: rgb(255, 255, 255);
	/* @apply bg-gradient-to-r from-cyan-500 to-blue-500 bg-opacity-10; */
	@apply bg-gradient-to-tr from-slate-200  to-slate-300 bg-no-repeat min-h-full;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Noto Sans', sans-serif;
}

body {
	margin: 0;
}

table {
	border-spacing: 0;
}

thead {
	@apply bg-slate-400 m-0;
}

th {
	@apply bg-slate-400 p-0.5 text-center;
}

td {
	@apply text-center p-1;
}

table tr:nth-child(even) {
	background-color: rgb(191 202 216);
}

table tr:hover {
	@apply bg-slate-100;
}

.ReactModal__Overlay--after-open{
	overflow-y: auto;
}
.ReactModal__Content {
	position: static !important;
	transform: translate(0) !important;
	margin: 30px auto;
}