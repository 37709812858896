.wrapper {
	@apply flex flex-col mx-auto w-1/3 mb-6;

	> h1 {
		@apply mx-auto text-center;
	}

	> button {
		@apply self-center;
	}

	.select {
		> label {
			@apply block text-left mb-2;

			> span {
				@apply text-xs uppercase text-gray-600 block;
				&::before {
					content: '*';
					color: red;
				}
			}
		}

		&.error {
			div {
				border-color: red;
			}

			p {
				@apply text-sm w-full self-center mx-auto text-red-600;
			}
		}

		&.checkbox {
			label {
				@apply flex flex-row-reverse justify-end gap-2;
			}
		}
	}

	.info {
		p {
			@apply text-center leading-5 text-sm;

			&.red {
				@apply my-1 text-center text-red-500;
			}
		}
	}

	.radioBlock {
		@apply flex flex-col content-center mb-2;

		> label {
			@apply flex items-center gap-x-2;
			> input {
				@apply rounded-lg outline-none border-none;
			}
		}
	}

	.importExport {
		@apply flex flex-col mb-2 gap-1;

		.title {
			@apply text-xs uppercase text-gray-600 block;

			.required {
				&::before {
					content: '*';
					color: red;
				}
			}
		}

		table {
			svg {
				@apply cursor-pointer;

				&:hover {
					@apply fill-red-500;
				}
			}
		}

		.error {
			@apply text-sm w-full self-center mx-auto text-red-600;
		}
	}

	.btns {
		@apply flex flex-col gap-2 justify-center mt-4;
	}

	@media (min-width: 1440px) {
		width: 450px;
	}
	@media (max-width: 1000px) {
		@apply w-1/2;
	}
	@media (max-width: 710px) {
		@apply w-full;
	}
}
