.wrapper {
	@apply relative;
	height: 90vh;

	.main {
		@apply absolute flex flex-col items-center w-full top-1/2 left-1/2 max-[810px]:flex-col;
		transform: translate(-50%, -50%);

		@media (min-width: 1030px) {
			@apply top-2/5;
		}

		.heading {
			@apply flex items-center justify-between gap-4 mb-10 mx-auto;

			h1 {
				@apply text-3xl;

				@media (max-width: 930px) {
					@apply text-2xl;
				}
			}

			img {
				max-height: 70px;

				@media (max-width: 930px) {
					width: 220px;
					height: 150px;
				}
			}

			@media (max-width: 980px) {
				@apply gap-x-2;
			}

			@media (max-width: 750px) {
				@apply flex-col mb-6;
			}

			@media (max-width: 380px) {
				@apply mb-2;
			}

			@media (min-width: 2300px) {
				@apply gap-x-20;
			}
		}

		.forms {
			@apply flex justify-between w-1/2 max-[1200px]:w-2/3 max-[890px]:w-3/4 max-[810px]:flex-col max-[810px]:w-full;
		}

		h1 {
			@apply self-center text-center;
		}
	}

	.requestStatus,
	.auth {
		@apply flex flex-col  mb-1;
		> input {
			@apply mb-2;
		}
		label {
			@apply mx-auto;
		}
		> .mainHeader {
			@apply mb-2;
		}
		h1 {
			@apply max-[380px]:mt-0;
		}
	}

	button {
		@apply mx-auto mt-2;
	}
}

@media (max-width: 1220px) {
	.main {
		@apply w-2/3;
	}
}

@media screen and (max-width: 910px) {
	.main {
		@apply w-3/4;
	}
}

@media screen and (max-width: 810px) {
	.main {
		@apply flex-col;
		label {
			width: 300px;
		}
	}

	.auth {
		> h1 {
			@apply mt-6;
		}
	}
}
