.common {
	> label {
		@apply block;

		> span {
			@apply text-xs uppercase text-gray-600 block;
		}
	}
}

textarea,
input {
	@apply border-2 border-solid py-1 px-2 text-gray-600 rounded-md text-lg;

	&:active {
		@apply border-primary;
	}
}

.field {
	@apply text-left mb-2;

	input,
	textarea {
		width: 100%;

		&:disabled {
			color: hsl(0, 0%, 60%);
			background-color: hsl(0, 0%, 95%);
			border-color: #999aa5;
		}
	}

	&.error {
		input,
		textarea {
			border-color: red;
		}

		> label > p {
			@apply text-sm w-full self-center mx-auto text-red-600;
		}
	}
}

.required {
	&::before {
		content: '*';
		color: red;
	}
}

.select {
	@apply text-left mb-2;

	&.error {
		> label {
			@apply text-red;
		}
		> p {
			@apply text-sm w-full text-red;
		}
	}
}
