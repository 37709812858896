.modal {
	.header {
		@apply flex justify-between items-center;

		h2 {
			@apply mx-auto;
		}

		svg {
			@apply cursor-pointer self-start;
		}
	}

	.body {
		@apply flex flex-row justify-evenly;

		&.choice {
			@apply flex flex-col gap-2;
		}

		.select {
			> label {
				@apply block text-left mb-2;

				> span {
					@apply text-xs uppercase text-gray-600 block;
				}
			}

			&.error {
				div {
					border-color: red;
				}

				p {
					@apply text-sm w-full self-center mx-auto text-red-600;
				}
			}
		}

		p {
			@apply px-3 flex flex-col;
		}

		&.form {
			@apply flex-col;
		}
	}
}
