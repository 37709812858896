.advertisements {
	@apply flex flex-col w-2/3 m-auto my-4;

	.adv {
		@apply border border-black border-solid rounded-md p-4;

		.date {
			@apply text-right mb-2;
		}
		.text {
			@apply text-center;
		}

		> p {
			margin-bottom: 5px !important;
		}
	}

	@media (max-width: 770px) {
		@apply w-full;
	}
}
