.wrapper {
	@apply absolute top-1/2 left-1/2 flex flex-col items-center text-center w-full p-4;
	transform: translate(-50%, -50%);

	h1 {
		font-size: 20rem;
		line-height: 1;
		user-select: none;
	}

	h2 {
		font-size: 2rem;
		font-weight: 500;
		margin-bottom: 5px;
	}

	p {
		font-size: 1.7rem;
		font-weight: 400;
		cursor: pointer;
		font-style: italic;
	}

	@media (max-width: 600px) {
		h1 {
			font-size: 15rem;
		}
	}

	@media (max-width: 465px) {
		@apply p-2;

		h1 {
			font-size: 10rem;
		}
		h2 {
			font-size: 1.7rem;
		}
		p {
			font-size: 1.5rem;
		}
	}
}
